import React, {useState, useEffect} from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import {styles} from '../../../styles';
import {Navbar, VerifyEmailAndPhone} from '../../../components';
import {showErrorMessage} from '../../../service/flashMessage';
import {initialAuthState} from '../utils';
import {fetchPerson} from '../../../api/people';
import {RootStore} from '../../../redux/store';
import {useSelector} from 'react-redux';
import ZoomableImage from '../../../components/ZoomableImage';
import {deriveDisplayName} from '../../../utils';

const PeopleDetailComponent = ({navigation, route}) => {
  const {personId} = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [person, setPerson] = useState(initialAuthState);
  const activePerson = useSelector((state: RootStore) => state.auth.user);

  const fetchUser = async id => {
    try {
      const user = await fetchPerson(id);
      setPerson(user);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchUser(personId);
  }, [personId]);

  return (
    <View style={localStyles.container}>
      <Navbar navigation={navigation} title="Profile" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Card style={localStyles.card}>
            <View style={localStyles.header}>
              <ZoomableImage
                title={deriveDisplayName(person)}
                source={{
                  uri: person.profilePicture
                    ? `data:image/jpeg;base64,${person.profilePicture}`
                    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
                }}
                style={styles.image}
              />
              <View style={localStyles.info}>
                <Text style={styles.userName}>{`Firstname : ${person.firstName}`}</Text>
                <Text style={styles.email}>{`Email : ${person.email}`}</Text>
              </View>
            </View>
            {activePerson?.id === personId && (
              <VerifyEmailAndPhone person={person} />
            )}
          </Card>
          <ScrollView style={localStyles.scrollView} showsVerticalScrollIndicator={false}>
            <Card style={localStyles.detailsCard}>
              {renderDetail('Title', person.title)}
              {renderDetail('Type', person.type)}
              {renderDetail('First name', person.firstName)}
              {renderDetail('Middle name', person.middleName)}
              {renderDetail('Last name', person.lastName)}
              {renderDetail('Company', person.company)}
              {renderDetail('Salutation', person.salutation)}
              {renderDetail('Address Line 1', person.addressLine1)}
              {renderDetail('Address Line 2', person.addressLine2)}
              {renderDetail('City', person.city)}
              {renderDetail('State', person.state)}
              {renderDetail('Zip', person.zip)}
              {renderDetail('Country', person.country)}
              {renderDetail('Phone 1', person.phone1)}
              <View style={localStyles.notes}>
                <Text style={styles.textLabel}>Notes</Text>
                <Text style={styles.textLabel}>{person.notes}</Text>
              </View>
              {renderDetail('Username', person.username)}
            </Card>
          </ScrollView>
        </>
      )}
    </View>
  );
};

const renderDetail = (label, value) => (
  <>
    <View style={localStyles.detailRow}>
      <Text style={styles.textLabel}>{label}</Text>
      <Text style={styles.detailsText}>{value}</Text>
    </View>
    <Divider />
  </>
);

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card: {
    marginHorizontal: 8,
    marginTop: 16,
    paddingBottom: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
  },
  info: {
    flex: 1,
    marginLeft: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    paddingVertical: 16,
  },
  detailsCard: {
    padding: 8,
    marginBottom: 32,
  },
  detailRow: {
    flexDirection: 'row',
    marginVertical: 12,
  },
  notes: {
    marginVertical: 12,
  },
});

export default PeopleDetailComponent;
