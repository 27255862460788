// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState} from 'react';
import {ScrollView, TouchableOpacity, View, Image, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import {ActivityIndicator} from 'react-native-paper';
import AsterixSwitch from '../../../components/AsterixSwitch';
import Icon from 'react-native-vector-icons/Feather';
import {Navbar, SubmitButton} from '../../../components';
import {colorSwatches, styles as globalStyles} from '../../../styles';
import {initialAuthState} from '../utils';
import {soundexConverter} from '../../../utils';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {
  updateUserAccount,
  postDriverLicense,
  fetchPerson,
} from '../../../api/people';
import {useEffect} from 'react';
import {directoryScopes, initialLinkState} from '../../UnitPeopleLink/utils';
import {getDeriveLinkFromState, getDeriveUserFromState} from '../utils';
import {getUnitLinkById, updateUnitPeopleLink} from '../../../api/links';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {GetListPeopleTypes} from '../../../redux/reducers/listPeopleTypesReducer';
import IconButton from '../../../components/IconButton';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';

const EditPeople = ({route, navigation}) => {
  const {userId, linkId} = route.params;
  const [hasPeopleId, setHasPeopleId] = useState(false);
  const [authState, setAuthState] = useState({
    ...initialAuthState,
    ...initialLinkState,
  });
  const dispatch = useDispatch();
  const {listPeopleTypes, loading} = useSelector(
    (state: RootStore) => state.listPeopleTypes,
  );
  const [dlValid, setDlValid] = useState(false);

  const [crunchingLicense, setCrunchingLicense] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [togglesDisabled, setTogglesDisabled] = useState(true);

  const [personalSectionVisible, setPersonalSectionVisible] = useState(false);
  const [linkingSectionVisible, setLinkingSectionVisible] = useState(false);
  const [directorySectionVisible, setDirectorySectionVisible] = useState(false);
  const scopes = directoryScopes;

  const [panelActive, setPanelActive] = useState(false);
  const [currentImageType, setCurrentImageType] = useState(null);


  const fields = [
    {
      fieldName: 'Username',
      state: 'username',
      type: 'textInput',
    },
  ];

  const titles = ['Mr.', 'Mrs.', 'Ms.', 'Dr.'];

  const phoneTypes = ['Company', 'Home', 'Landline'];

  const fetchData = async (personId, linkId) => {
    dispatch(GetListPeopleTypes());
    try {
      const person = await fetchPerson(personId);
      const link = await getUnitLinkById(linkId);
      setAuthState({
        ...authState,
        peopleId: person.id,
        linkId: linkId,
        peopleType: link.personType,
        ...link,
        ...person,
      });
      setHasPeopleId(link.peopleId);
      // updatePermissions('', results);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const submitUpdatedUser = async (userIdToEdit, user) => {
    const updatedUser = user;
    delete updatedUser.login;
    delete updatedUser.password;
    delete updatedUser.username;
    !dlValid && delete updatedUser.driversLicenseImage;
    const derivedUser = getDeriveUserFromState(updatedUser);

    const derivedLink = getDeriveLinkFromState(updatedUser);
    try {
      await updateUserAccount(userIdToEdit, derivedUser);
      await updateUnitPeopleLink(linkId, derivedLink);
      showSuccessMessage('User updated successfully');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsSubmitting(false);
  };

  const fetchDriverLicenseData = async base64String => {
    try {
      const res = await postDriverLicense({imageBase64: base64String.data});
      updateStateWithLicense(res, base64String);
    } catch (e) {
      setDlValid(false);
      showErrorMessage(e.message);
    }

    setCrunchingLicense(false);
  };

  const updateStateWithLicense = (license, base64String) => {
    if (license) {
      setDlValid(true);
      setAuthState({
        ...authState,
        driversLicenseImage: base64String.data,
        state: license.state,
        firstName: license.first_name,
        middleName: license.middle_name,
        lastName: license.last_name,
        addressLine1: license.address,
        addressLine2: license.full_address,
        city: license.city,
        zip: license.zip,
      });
    } else {
      setDlValid(false);
      showErrorMessage('Please upload a valid license');
    }
  };

  const updateStateWithImages = (image, imageType) => {
    setAuthState({
      ...authState,
      [imageType]: image.data,
    });
    if (imageType === 'driversLicenseImage') {
      setCrunchingLicense(true);
      fetchDriverLicenseData(image);
    }
  };

  useEffect(() => {
    fetchData(userId, linkId);
  }, [linkId, userId]);

  const updatePermissions = (peopleType, peopleTypesList) => {
    // console.log(peopleTypesList);
    const personType = peopleTypesList.filter(
      type => type.peopleType === peopleType,
    )[0];
    setAuthState({
      ...authState,
      peopleType: personType.peopleType,
      canManagePeople: personType.normallyCanManagePeople,
      allowedToChekoutKey: personType.normallyAllowedToChekoutKey,
      allowedToClaimPackages: personType.normallyAllowedToClaimPackages,
      allowedToAuthorizeEntry: personType.normallyallowedToAuthorizeEntry,
      alwaysNeedsEntrancePermission: !personType.autoCheckIn,
      canViewLog: personType.normallyCanViewLog,
      unitPermission: personType.normallyUnitPermission,
      peoplePermission: personType.normallyPeoplePermission,
      vehiclePermission: personType.normallyVehiclePermission,
      petPermission: personType.normallyPetPermission,
      storagePermission: personType.normallyStoragePermission,
      parkingPermission: personType.normallyParkingPermission,
      violationsPermission: personType.normallyViolationsPermission,
    });
    setTogglesDisabled(false);
  };

  return (
    <View style={styles.flex1}>
      <Navbar navigation={navigation} title={'Edit Person'} />
      <ScrollView style={styles.scrollView}>
      {crunchingLicense ? (
        <View style={styles.licenseProcessing}>
        <ActivityIndicator />
        <Text> Extracting data from license...</Text>
        </View>
      ) : (
        <View>
        <Text style={[styles.textInputLabel, styles.marginY2]}>
          Drivers License picture
        </Text>
        <TouchableOpacity
          onPress={() => {
          setCurrentImageType('driversLicenseImage');
          setPanelActive(true);
          }}
          style={styles.pictureUploadContainer}>
          {authState.driversLicenseImage ? (
          <Image
            source={{
            uri: `data:image/jpeg;base64,${authState.driversLicenseImage}`,
            }}
            style={styles.image}
          />
          ) : (
          <Icon name="image" size={24} style={styles.pictureUploadIcon} />
          )}
        </TouchableOpacity>
        </View>
      )}
      <PhotoCaptureSheet
        isPanelActive={panelActive}
        setIsActive={(val: boolean) => setPanelActive(val)}
        onConfirmImage={imgData =>
        updateStateWithImages(imgData, currentImageType)
        }
      />
      {fields.map(field => {
        return (
        <View key={field.fieldName}>
          <Text style={styles.textInputLabel}>{field.fieldName}</Text>
          <Input
          placeholder={`Enter ${field.fieldName}`}
          isReadOnly={hasPeopleId}
          value={authState[field.state]}
          onChangeText={text =>
            setAuthState({...authState, [field.state]: text})
          }
          />
        </View>
        );
      })}
      <>
        <Text style={styles.textInputLabel}>Email</Text>
        <Input
        isReadOnly={hasPeopleId}
        placeholder="Enter email"
        value={authState.email}
        onChangeText={text => setAuthState({...authState, email: text})}
        keyboardType="email-address"
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Phone 1</Text>
        <Input
        isReadOnly={hasPeopleId}
        placeholder="Enter phone 1"
        value={authState.phone1}
        keyboardType="phone-pad"
        onChangeText={text =>
          setAuthState({...authState, phone1: text, phone1Stripped: text})
        }
        />
        <Text style={styles.marginTop4}>Phone 1 type</Text>
        <Select
        minWidth={200}
        selectedValue={authState.phone1Type}
        onValueChange={val =>
          setAuthState({...authState, phone1Type: val})
        }>
        {phoneTypes.map(type => (
          <Select.Item key={type} label={type} value={type} />
        ))}
        </Select>
        <View style={styles.checkboxContainer}>
        <Text style={styles.checkBoxLabel}>
          Phone 1 Can receive Text messages?
        </Text>
        <Checkbox
          style={styles.checkbox}
          value={authState.phone1CanReceiveText}
          onChange={value =>
          setAuthState({...authState, phone1CanReceiveText: value})
          }
          aria-label="Remember me"
        />
        </View>
      </>
      <>
        <Text style={styles.textInputLabel}>First name</Text>
        <Input
        placeholder="Enter first name"
        value={authState.firstName}
        onChangeText={text =>
          setAuthState({
          ...authState,
          firstName: text,
          firstnameSoundx: soundexConverter(text),
          })
        }
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Middle name</Text>
        <Input
        placeholder="Enter middle name"
        value={authState.middleName}
        onChangeText={text =>
          setAuthState({...authState, middleName: text})
        }
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Last name</Text>
        <Input
        placeholder="Enter last name"
        value={authState.lastName}
        onChangeText={text =>
          setAuthState({
          ...authState,
          lastName: text,
          lastNameSoundx: soundexConverter(text),
          })
        }
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Company</Text>
        <Input
        placeholder="Enter company"
        value={authState.company}
        onChangeText={text => setAuthState({...authState, company: text})}
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Address Line 1</Text>
        <Input
        placeholder="Enter Address Line 1"
        value={authState.addressLine1}
        onChangeText={text =>
          setAuthState({...authState, addressLine1: text})
        }
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Address Line 2</Text>
        <Input
        placeholder="Enter Address Line 2"
        value={authState.addressLine2}
        onChangeText={text =>
          setAuthState({...authState, addressLine2: text})
        }
        />
      </>
      <>
        <Text style={styles.textInputLabel}>City</Text>
        <Input
        placeholder="Enter city"
        value={authState.city}
        onChangeText={text => setAuthState({...authState, city: text})}
        />
      </>
      <>
        <Text style={styles.textInputLabel}>State</Text>
        <Input
        placeholder="Enter state"
        value={authState.state}
        onChangeText={text => setAuthState({...authState, state: text})}
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Zip</Text>
        <Input
        placeholder="Enter zip"
        value={authState.zip}
        onChangeText={text => setAuthState({...authState, zip: text})}
        />
      </>
      <Text style={styles.marginTop4}>Title</Text>
      <Select
        minWidth={200}
        selectedValue={authState.title}
        onValueChange={val => setAuthState({...authState, title: val})}>
        {titles.map(title => (
        <Select.Item key={title} label={title} value={title} />
        ))}
      </Select>
      <>
        <Text style={styles.textInputLabel}>Salutation</Text>
        <Input
        placeholder="Enter salutation"
        value={`${authState.title} ${authState.lastName} ${authState.salutation}`}
        />
      </>
      <>
        <Text style={styles.textInputLabel}>Notes</Text>
        <Input
        placeholder="Enter notes"
        value={authState.notes}
        multiline
        onChangeText={text => setAuthState({...authState, notes: text})}
        />
      </>
      <View>
        <Text style={styles.textInputLabel}>Profile picture</Text>
        <TouchableOpacity
        onPress={() => {
          setCurrentImageType('profilePicture');
          setPanelActive(true);
        }}
        style={styles.pictureUploadContainer}>
        {authState.profilePicture ? (
          <Image
          source={{
            uri: `data:image/jpeg;base64,${authState.profilePicture}`,
          }}
          style={styles.image}
          />
        ) : (
          <Icon name="image" size={24} style={styles.pictureUploadIcon} />
        )}
        </TouchableOpacity>
      </View>

      <Text style={styles.marginTop4}>People type</Text>
      <Select
        minWidth={200}
        selectedValue={authState.peopleType}
        onValueChange={val => updatePermissions(val, listPeopleTypes)}>
        <Select.Item label={'Select people type'} value={''} />
        {listPeopleTypes.map(peopleType => (
        <Select.Item
          key={peopleType.id}
          label={peopleType.peopleType}
          value={peopleType.peopleType}
        />
        ))}
      </Select>
      {personalSectionVisible && (
        <>
        <Text style={styles.textInputLabel}>Type</Text>
        <Input
          placeholder="Enter type"
          value={authState.type}
          onChangeText={text => setAuthState({...authState, type: text})}
        />
        <Text style={styles.textInputLabel}>Country</Text>
        <Input
          placeholder="Enter country"
          value={authState.country}
          onChangeText={text => setAuthState({...authState, country: text})}
        />
        </>
      )}

      <>
        <TouchableOpacity
        style={styles.sectionToggle}
        onPress={() => setLinkingSectionVisible(!linkingSectionVisible)}>
        <View style={styles.flexRow}>
          <Text style={styles.sectionTitle}>
          Linking information
          </Text>
          <IconButton
          icon={linkingSectionVisible ? 'chevron-up' : 'chevron-down'}
          onPress={() => setLinkingSectionVisible(!linkingSectionVisible)}
          />
        </View>
        </TouchableOpacity>
        <>
        {linkingSectionVisible && (
          <>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>
            Needs help, in emergency ?
            </Text>
            <AsterixSwitch
            value={authState.needsHelpInEmergency}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              needsHelpInEmergency: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>Can manage people ?</Text>
            <AsterixSwitch
            value={authState.canManagePeople}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              canManagePeople: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>
            Allowed to checkout key ?
            </Text>
            <AsterixSwitch
            value={authState.allowedToChekoutKey}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              allowedToChekoutKey: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>
            Allowed to claim packages ?
            </Text>
            <AsterixSwitch
            value={authState.allowedToClaimPackages}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              allowedToClaimPackages: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>
            Allowed to authorize entry ?
            </Text>
            <AsterixSwitch
            value={authState.allowedToAuthorizeEntry}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              allowedToAuthorizeEntry: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>Mail to ?</Text>
            <AsterixSwitch
            value={authState.mailTo}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              mailTo: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>
            Always needs entrance permission ?
            </Text>
            <AsterixSwitch
            value={authState.alwaysNeedsEntrancePermission}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              alwaysNeedsEntrancePermission: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>Can view log ?</Text>
            <AsterixSwitch
            value={authState.canViewLog}
            disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              canViewLog: value,
              })
            }
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.checkBoxLabel}>Resident ?</Text>
            <AsterixSwitch
            value={authState.resident}
            // disabled={togglesDisabled}
            color={'#007bff'}
            onValueChange={value =>
              setAuthState({
              ...authState,
              resident: value,
              })
            }
            />
          </View>
          <>
            <Text style={styles.textInputLabel}>Access instructions</Text>
            <Input
            placeholder="Enter access instructions"
            value={authState.accessInstructions}
            style={styles.multiLinetextInput}
            multiline
            onChangeText={text =>
              setAuthState({
              ...authState,
              accessInstructions: text,
              })
            }
            />
          </>
          <>
            <Text style={styles.textInputLabel}>Relationship</Text>
            <Input
            placeholder="Enter relationship"
            value={authState.relationship}
            onChangeText={text =>
              setAuthState({...authState, relationship: text})
            }
            />
          </>
          </>
        )}
        </>
      </>
      </ScrollView>
      {isSubmitting || crunchingLicense ? (
      <ActivityIndicator animating color={colorSwatches.primary.color} />
      ) : (
      <SubmitButton
        onPress={() => {
        setIsSubmitting(true);
        submitUpdatedUser(authState.peopleId, authState);
        }}
        title="Update"
      />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 4,
    paddingBottom: 12,
  },
});

export default EditPeople;
