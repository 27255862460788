// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View, TouchableOpacity, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator} from 'react-native-paper';

import {Navbar} from '../../../components';
import {colorSwatches, styles} from '../../../styles';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import Icon from 'react-native-vector-icons/Feather';
import DateTimeInput from '../../../components/DateTimeInput';
import {toLocalDateTime} from '../../../utils';
import {dateFrameRequestAccess} from '../../../api/dataframe';
import FormContainer from '../../../components/FormContainer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import VStack from '../../../components/VStack';

const CreateLinkRequest = ({route, navigation}) => {
  const {unitId} = route.params;
  const [dateFrameState, setDateFrameState] = useState({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    description: '',
    unitId,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(1);

  const handleStartDateConfirm = date => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + (parseInt(numberOfDays, 10) - 1));

    setDateFrameState({...dateFrameState, startDate: date, endDate: newDate});
  };

  const handleEndDateConfirm = date => {
    const diffTime = new Date(date) - new Date(dateFrameState.startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setNumberOfDays(diffDays + 1);
    setDateFrameState({...dateFrameState, endDate: date});
  };

  const submitLinkRequest = async () => {
    setIsSubmitting(true);
    if (!dateFrameState.description) {
      dateFrameState.description = `${new Date(
        dateFrameState.startDate,
      ).toDateString()} - ${new Date(dateFrameState.endDate).toDateString()}`;
    }

    const start = new Date(dateFrameState.startDate);
    const end = new Date(dateFrameState.endDate);

    const dateframeData = {
      unitId: unitId,
      startDate: toLocalDateTime(start, true),
      endDate: toLocalDateTime(end, false),
      description: dateFrameState.description,
    };
    try {
      await dateFrameRequestAccess(dateframeData);
      showSuccessMessage('Request sent');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsSubmitting(false);
  };

  return (
    <View style={localStyles.container}>
      <Navbar navigation={navigation} title="Add Custom time frame" />
      <FormContainer>
        <ScrollView
          style={localStyles.scrollView}
          keyboardShouldPersistTaps="handled">
          <VStack>
            <DateTimeInput
              label="From"
              value={new Date(dateFrameState.startDate)}
              onConfirm={date => {
                handleStartDateConfirm(date);
              }}
              mode="date"
            />
            <VStack>
              <Text style={[styles.textInputLabel, localStyles.marginTop]}>
                Days
              </Text>
              <Input
                placeholder="Enter number of days"
                value={numberOfDays.toString()}
                keyboardType="number-pad"
                onChangeText={text => {
                  setNumberOfDays(parseInt(text === '' ? 0 : text, 10));
                  const date = new Date(dateFrameState.startDate);
                  date.setDate(
                    date.getDate() + parseInt(text === '' ? 0 : text, 10),
                  );
                  setDateFrameState({...dateFrameState, endDate: date});
                }}
                minWidth={200}
                InputRightElement={
                  <TouchableOpacity
                    style={localStyles.plusButton}
                    onPress={() => {
                      let newDays = numberOfDays + 1;
                      setNumberOfDays(newDays);
                      const date = new Date(dateFrameState.startDate);
                      date.setDate(date.getDate() + newDays - 1);
                      setDateFrameState({...dateFrameState, endDate: date});
                    }}>
                    <Icon name="plus" size={24} color="#007bff" />
                  </TouchableOpacity>
                }
              />
            </VStack>
            <DateTimeInput
              label="To"
              value={new Date(dateFrameState.endDate)}
              onConfirm={date => {
                handleEndDateConfirm(date);
              }}
              mode="date"
            />
            <>
              <Text style={styles.textInputLabel}> Description</Text>
              <TextArea
                placeholder="Enter description"
                value={dateFrameState.description}
                style={styles.multiLinetextInput}
                multiline
                onChangeText={text =>
                  setDateFrameState({...dateFrameState, description: text})
                }
              />
            </>
          </VStack>
        </ScrollView>

        {isSubmitting ? (
          <ActivityIndicator animating color={colorSwatches.primary.color} />
        ) : (
          <Button
            onPress={() => {
              dateFrameState.startDate && dateFrameState.endDate
                ? submitLinkRequest()
                : showErrorMessage('Please select or create a new dateframe');
            }}
            style={localStyles.submitButton}>
            Submit
          </Button>
        )}
      </FormContainer>
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  marginTop: {
    marginTop: 8,
  },
  plusButton: {
    paddingHorizontal: 16,
  },
  submitButton: {
    marginBottom: 64,
    paddingVertical: 16,
    marginHorizontal: 16,
  },
});

export default CreateLinkRequest;
