import {User} from '../@types';
import {UnitLink} from '../@types/UnitLink';

export const deriveDisplayName = (user: User) => {
  let displayName = null;

    displayName = `${user?.firstName !== null ? user?.firstName : ''} ${
      user?.lastName !== null ? user?.lastName : ''
    }`;
  return displayName.length > 1 ? displayName : 'Not provided';
};

export function deriveUnitName(unitLink: UnitLink) {
  let name = unitLink.unit.unitName;


  return name;
}
