// @ts-nocheck
import React from 'react';
import {View, Alert, ScrollView, useWindowDimensions, StyleSheet} from 'react-native';
import AsterixSwitch from '../../../components/AsterixSwitch';
import Text from '../../../components/Text';
import {styles as globalStyles} from '../../../styles';
import {useState} from 'react';
import {useEffect} from 'react';
import {UnitLink} from '../../../@types/UnitLink';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {GetListPeopleTypes} from '../../../redux/reducers/listPeopleTypesReducer';
import {ListPeopleType} from '../../../@types/ListPeopleType';
import {LoadingSpinner, PermissionsWarning} from '../../../components';
import {mapPermissionStringToSwitches} from '../../../utils';
import {useUpdatePermissions} from '../../../hooks/permissions';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Accordion from '../../../components/Accordion';
import Badge from '../../../components/Badge';
import HStack from '../../../components/HStack';
import Button from '../../../components/Button';
import PermissionInfoText from '../../../components/PermissionInfoText';
import {VStack} from 'native-base';

export default function EditOthersUnitLink({
  initialPeopleState,
  updateParentState,
  isEditMode,
  unitPeopleLink,
}) {
  const [unitPeopleState, setUnitPeopleState] =
    useState<UnitLink>(initialPeopleState);
  const {listPeopleTypes, loading} = useSelector(
    (state: RootStore) => state.listPeopleTypes,
  );
  const [permissions, setPermissions] = useState([]);
  const [togglesDisabled, setTogglesDisabled] = useState(true);
  const dispatch = useDispatch();
  const {mutate, isLoading} = useUpdatePermissions();
  const {width} = useWindowDimensions();


  useEffect(() => {
    setPermissions(mapPermissionStringToSwitches(unitPeopleState));
    dispatch(GetListPeopleTypes());
  }, []);

  const updatePermissions = (peopleTypeId: string) => {
    const personType: ListPeopleType = listPeopleTypes.filter(
      type => type.id === peopleTypeId,
    )[0];

    const nextState: UnitLink = {
      ...unitPeopleState,
      peopleType: {
        id: personType.id,
        name: personType.peopleType,
        residentCanAdd: personType.residentCanAdd,
      },
      canManagePeople: personType.normallyCanManagePeople,
      allowedToChekoutKey: personType.normallyAllowedToChekoutKey,
      allowedToClaimPackages: personType.normallyAllowedToClaimPackages,
      allowedToAuthorizeEntry: personType.normallyallowedToAuthorizeEntry,
      alwaysNeedsEntrancePermission: !personType.autoCheckIn,
      canViewLog: personType.normallyCanViewLog,
      peoplePermission: personType.normallyPeoplePermission,
      unitPermission: personType.normallyUnitPermission,
      vehiclePermission: personType.normallyVehiclePermission,
      petPermission: personType.normallyPetPermission,
      storagePermission: personType.normallyStoragePermission,
      parkingPermission: personType.normallyParkingPermission,
      violationsPermission: personType.normallyViolationsPermission,
      deliveryPermission: personType.normallyDeliveryPermission,
      gateCardPermission: personType.normallyGateCardPermission,
      getNotifications: personType.normallyGetsNotifications,
      //people without "Allowed to Authorize Entry" should not be able to edit the Always call permission
      alwaysCall: unitPeopleLink.allowedToAuthorizeEntry
        ? personType.normallyAlwaysCall
        : unitPeopleState.alwaysCall,
      canEditSelf: personType.normallyCanEditSelf,
      allowedtoApprove: personType.normallyAllowedtoApprove,
      allowedToRequestAccess: personType.normallyallowedToRequestAccess,
      allowedToApprove: personType.normallyAllowedtoApprove,
      needsHelpInEmergency: personType.normallyNeedsHelpInEmergency,

      privateFilesManage: personType.normallyPrivateFilesManage,
      privateFilesAccess: personType.normallyPrivateFilesAccess,
      ownerFilesManage: personType.normallyOwnerFilesManage,
      ownerFilesAccess: personType.normallyOwnerFilesAccess,
      managePublicFiles: personType.normallyManagePublicFiles,
    };

    setPermissions(mapPermissionStringToSwitches(nextState));
    setUnitPeopleState(nextState);
    updateParentState(nextState);
    //Disabled editing of toggles
    // setTogglesDisabled(!isEditMode);
    setTogglesDisabled(true);
  };

  const showManagementPrompt = (peopleTypeObj: ListPeopleType) => {
    Alert.alert(
      'Alert',
      `Adding ${peopleTypeObj.peopleType} will require management approval .`,
      [
        {
          text: 'CANCEL',
          onPress: () => updatePermissions(''),
        },
        {
          text: 'OK',
          onPress: () => updatePermissions(peopleTypeObj.id),
        },
      ],
      {
        cancelable: false,
      },
    );
    return true;
  };

  const handlePermissionsToggle = newPermissions => {
    const resourcePermissions = {};
    newPermissions.forEach(permission => {
      resourcePermissions[permission.title.toLowerCase() + 'Permission'] =
        permission.permissions.map(perm => (perm.value ? '1' : '0')).join('');
    });
    setUnitPeopleState({
      ...unitPeopleState,
      ...resourcePermissions,
    });
  };

  const fieldsDidChange = () => {
    const initialState: UnitLink = initialPeopleState;

    return (
      unitPeopleState.alwaysCall !== initialState.alwaysCall ||
      unitPeopleState.getNotifications !== initialState.getNotifications ||
      unitPeopleState.allowedtoApprove !== initialState.allowedtoApprove ||
      unitPeopleState.emergencyContact !== initialState.emergencyContact ||
      unitPeopleState.canManagePeople !== initialState.canManagePeople ||
      unitPeopleState.allowedToChekoutKey !==
        initialState.allowedToChekoutKey ||
      unitPeopleState.allowedToClaimPackages !==
        initialState.allowedToClaimPackages ||
      unitPeopleState.allowedToAuthorizeEntry !==
        initialState.allowedToAuthorizeEntry ||
      unitPeopleState.alwaysNeedsEntrancePermission !==
        initialState.alwaysNeedsEntrancePermission ||
      unitPeopleState.allowedToRequestAccess !==
        initialState.allowedToRequestAccess
    );
  };

  const submitButtonActive = fieldsDidChange();

  const [documentsPermissions, setDocumentsPemissions] = useState([
    {
      field: 'privateFilesManage',
      description: 'Manage private files',
    },
    {
      field: 'privateFilesAccess',
      description: 'Access private files',
    },
    {
      field: 'ownerFilesManage',
      description: 'Manage owner files',
    },
    {
      field: 'ownerFilesAccess',
      description: 'Access owner files',
    },
    {
      field: 'managePublicFiles',
      description: 'Manage public files',
    },
  ]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <View style={{flex: 1, marginBottom: 40}}>
    <ScrollView
      style={{
        marginBottom: 60,
        marginHorizontal: 8,
      }}>
      {!isEditMode && (
        <PermissionsWarning message="You do not have permissions to edit this record. Please Contact management." />
      )}
      {isEditMode ? (
        <>
          <Text style={{marginVertical: 16}}>People type *</Text>
          <Select
            placeholder="Select people type "
            minWidth={200}
            isDisabled
            selectedValue={
              unitPeopleState?.peopleType?.id
                ? unitPeopleState.peopleType?.id
                : unitPeopleState?.personType
            }
            onValueChange={value => {
              const peopleTypeObj = listPeopleTypes?.filter(
                item => item.id === value,
              )[0];
              !peopleTypeObj?.residentCanAdd
                ? showManagementPrompt(peopleTypeObj)
                : updatePermissions(value);
            }}>
            {listPeopleTypes?.map(peopleType => (
              <Select.Item
                key={peopleType.id}
                label={
                  peopleType?.residentCanAdd
                    ? peopleType?.peopleType
                    : `${peopleType?.peopleType} - ( requires management approval )`
                }
                value={peopleType.id}
              />
            ))}
          </Select>
        </>
      ) : (
        <View style={{flexDirection: 'row', marginVertical: 12}}>
          <Text style={styles.textLabel}>People type</Text>
          <Text style={styles.detailsText}>
            {unitPeopleState.peopleType.name}
          </Text>
        </View>
      )}

      <Accordion style={{marginTop: 16}} defaultIndex={[0]}>
        <Accordion.Item>
          <Accordion.Summary>
            {`${isEditMode ? 'Editable' : 'Basic'} Permissions`}
            <Accordion.Icon />
          </Accordion.Summary>
          <Accordion.Details>
            <>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Allowed to approve ?"
                  description="Allowed to approve description"
                />
                <AsterixSwitch
                  value={unitPeopleState.allowedtoApprove}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      allowedtoApprove: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      allowedtoApprove: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Allowed to request access ?"
                  description="Allowed to request access permission"
                />
                <AsterixSwitch
                  value={unitPeopleState.allowedToRequestAccess}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      allowedToRequestAccess: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      allowedToRequestAccess: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Allowed to authorize entry ?"
                  description="Allowed to authorize entry description"
                />
                <AsterixSwitch
                  value={unitPeopleState.allowedToAuthorizeEntry}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      allowedToAuthorizeEntry: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      allowedToAuthorizeEntry: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Allowed to checkout key ?"
                  description="Allowed to checkout description"
                />
                <AsterixSwitch
                  value={unitPeopleState.allowedToChekoutKey}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      allowedToChekoutKey: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      allowedToChekoutKey: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title=" Allowed to claim packages ?"
                  description="Allowed to claim packages description"
                />
                <AsterixSwitch
                  value={unitPeopleState.allowedToClaimPackages}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      allowedToClaimPackages: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      allowedToClaimPackages: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Always needs entrance permission ?"
                  description="Always needs entrance permission"
                />
                <AsterixSwitch
                  value={unitPeopleState.alwaysNeedsEntrancePermission}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      alwaysNeedsEntrancePermission: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      alwaysNeedsEntrancePermission: value,
                    });
                  }}
                />
              </View>

              <View style={styles.row}>
                <PermissionInfoText
                  title="Always call for gate authorizations ?"
                  description="Always call for gate authorizations ?"
                />
                <AsterixSwitch
                  //people without "Allowed to Authorize Entry" should not be able to edit the Always call permission
                  disabled={
                    !unitPeopleLink.allowedToAuthorizeEntry || !isEditMode
                  }
                  value={unitPeopleState.alwaysCall}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      alwaysCall: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      alwaysCall: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Can edit self ?"
                  description="Can edit self description"
                />
                <AsterixSwitch
                  value={unitPeopleState.canEditSelf}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      canEditSelf: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      canEditSelf: value,
                    });
                  }}
                  disabled
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Can manage people ?"
                  description="Can manage description"
                />
                <AsterixSwitch
                  value={unitPeopleState.canManagePeople}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      canManagePeople: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      canManagePeople: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Get notifications ?"
                  description="Get notifications description"
                />
                <AsterixSwitch
                  value={unitPeopleState.getNotifications}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      getNotifications: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      getNotifications: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Can view logs ?"
                  description="Can view log description"
                />
                <AsterixSwitch
                  value={unitPeopleState.canViewLog}
                  disabled={true}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      canViewLog: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      canViewLog: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Needs help in emergency ?"
                  description="Needs help in emergency description"
                />
                <AsterixSwitch
                  value={unitPeopleState.emergencyContact}
                  disabled={!isEditMode}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      needsHelpInEmergency: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      needsHelpInEmergency: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Resident ?"
                  description="Resident description"
                />
                <AsterixSwitch
                  value={unitPeopleState.resident}
                  disabled={togglesDisabled}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      resident: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      resident: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Emergency contact description ?"
                  description="Emergency contact"
                />
                <AsterixSwitch
                  value={unitPeopleState.emergencyContact}
                  disabled={togglesDisabled}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      emergencyContact: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      emergencyContact: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText title="Disabled" description="Disabled" />
                <AsterixSwitch
                  value={unitPeopleState.disabled}
                  disabled={togglesDisabled}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      disabled: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      disabled: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Can View UnitHistory"
                  description="Can View Unit History Description"
                />
                <AsterixSwitch
                  value={unitPeopleState.disabled}
                  disabled={togglesDisabled}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      canViewUnitHistory: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      canViewUnitHistory: value,
                    });
                  }}
                />
              </View>
              <View style={styles.row}>
                <PermissionInfoText
                  title="Auto send welcome message"
                  description="Autosend welcome message"
                />
                <AsterixSwitch
                  value={unitPeopleLink.autoSendWelcomeMessage}
                  disabled={true}
                  onValueChange={() => {}}
                />
              </View>
              <>
                <Text style={styles.textInputLabel}>Access instructions</Text>
                {isEditMode ? (
                  <Input
                    placeholder="Enter access instructions"
                    value={unitPeopleState.accessInstructions}
                    multiline
                    onChangeText={text => {
                      setUnitPeopleState({
                        ...unitPeopleState,
                        accessInstructions: text,
                      });
                      updateParentState({
                        ...unitPeopleState,
                        accessInstructions: text,
                      });
                    }}
                  />
                ) : (
                  <Text>{unitPeopleState.accessInstructions}</Text>
                )}
              </>
            </>
          </Accordion.Details>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Summary>
            Permissions
            <Accordion.Icon />
          </Accordion.Summary>
          <Accordion.Details>
            <>
              <HStack>
                <Badge colorScheme="dark" rounded="lg">
                  General
                </Badge>
              </HStack>

              <View style={styles.row}>
                <PermissionInfoText title="Mail to ?" description="Mail to" />
                <AsterixSwitch
                  value={unitPeopleState.mailTo}
                  disabled={togglesDisabled}
                  onValueChange={value => {
                    setUnitPeopleState({
                      ...unitPeopleState,
                      mailTo: value,
                    });
                    updateParentState({
                      ...unitPeopleState,
                      mailTo: value,
                    });
                  }}
                />
              </View>
            </>
            <>
              {permissions.map(permObj => {
                return (
                  <>
                    <HStack>
                      <Badge colorScheme="dark" rounded="lg" mt="4">
                        {permObj.title}
                      </Badge>
                    </HStack>
                    {permObj.permissions.map((permission, index) => {
                      return (
                        <View style={styles.row}>
                          <PermissionInfoText
                            title={`${permission.text} ?`}
                            description={`${permission.text} description`}
                          />
                          <AsterixSwitch
                            value={permission.value}
                            disabled={togglesDisabled}
                            onValueChange={value => {
                              const subPermissions = permObj.permissions.map(
                                item => {
                                  if (
                                    item.text ===
                                    permObj.permissions[index].text
                                  ) {
                                    return {
                                      ...item,
                                      value: value,
                                    };
                                  }
                                  return item;
                                },
                              );
                              const perms = permissions.map(p => {
                                return p.title === permObj.title
                                  ? {
                                      ...p,
                                      permissions: [...subPermissions],
                                    }
                                  : p;
                              });
                              setPermissions(perms);
                              handlePermissionsToggle(perms);
                            }}
                          />
                        </View>
                      );
                    })}
                  </>
                );
              })}
            </>
            <VStack>
              <HStack>
                <Badge colorScheme="dark" rounded="lg" mt="4">
                  Documents
                </Badge>
              </HStack>
              {documentsPermissions.map(perm => {
                return (
                  <View style={styles.row}>
                    <PermissionInfoText
                      title={perm.description}
                      description={perm.description}
                    />
                    <AsterixSwitch
                      value={unitPeopleState[perm.field]}
                      disabled={togglesDisabled}
                      onValueChange={() => {}}
                    />
                  </View>
                );
              })}
            </VStack>
          </Accordion.Details>
        </Accordion.Item>

        {/* This should be moved to EditOwnUnitLink section */}
        {/* <Accordion.Item>
          <Accordion.Summary>
            Directory Information
            <Accordion.Icon />
          </Accordion.Summary>
          <Accordion.Details>
            <>
              {unitPeopleState.peopleType.name !== 'Guest' ? (
                <>
                  <View style={{}}>
                    <Text style={styles.checkBoxLabel}>
                      Directory Opt out ?
                    </Text>
                    <AsterixSwitch
                      value={unitPeopleState.directoryOptOut}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          directoryOptOut: value,
                          directoryDoNotShareEmail: value,
                          directoryDoNotSharePhone1: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          directoryOptOut: value,
                          directoryDoNotShareEmail: value,
                          directoryDoNotSharePhone1: value,
                        });
                      }}
                    />
                  </View>

                  <>
                    <Text style={[styles.onePickerLabel, {marginTop: 0}]}>Directory scope</Text>
                    <Select
                      selectedValue={
                        unitPeopleState.directoryScope
                          ? unitPeopleState.directoryScope
                          : ''
                      }
                      onValueChange={val => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          directoryScope: val,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          directoryScope: val,
                        });
                      }}>
                      {directoryScopes.map(scope => (
                        <Select.Item key={scope} label={scope} value={scope} />
                      ))}
                    </Select>
                  </>
                  <View style={styles.row}>
                    <Text style={styles.checkBoxLabel}>
                      Directory do not share email ?
                    </Text>
                    <AsterixSwitch
                      value={unitPeopleState.directoryDoNotShareEmail}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          directoryDoNotShareEmail: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          directoryDoNotShareEmail: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.checkBoxLabel}>
                      Directory do not share phone 1 ?
                    </Text>
                    <AsterixSwitch
                      value={unitPeopleState.directoryDoNotSharePhone1}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          directoryDoNotSharePhone1: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          directoryDoNotSharePhone1: value,
                        });
                      }}
                    />
                  </View>
                  <>
                    <Text style={styles.textInputLabel}>Relationship</Text>
                    <Input
                      placeholder="Enter relationship"
                      value={unitPeopleState.relationship}
                      onChangeText={text => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          relationship: text,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          relationship: text,
                        });
                      }}
                    />
                  </>

                  <>
                    <Text style={styles.textInputLabel}>Office notes</Text>
                    <TextArea
                      autoCompleteType=""
                      placeholder="Enter office notes"
                      value={unitPeopleState.officeNotes}
                      multiline
                      onChangeText={text => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          officeNotes: text,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          officeNotes: text,
                        });
                      }}
                    />
                  </>

                  <>
                    <Text style={styles.textInputLabel}>Guard notes</Text>
                    <TextArea
                      autoCompleteType=""
                      placeholder="Enter guard notes"
                      value={unitPeopleState.guardNotes}
                      onChangeText={text => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          guardNotes: text,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          guardNotes: text,
                        });
                      }}
                    />
                  </>
                </>
              ) : (
                <Text>Directory information is not available for this person.</Text>
              )}
            </>
          </Accordion.Details>
        </Accordion.Item> */}
      </Accordion>

   
    </ScrollView>
    {isEditMode && (
        <Button
        style={{
          marginHorizontal: 16,
          marginBottom: 100
        }}
          onPress={() => {
            const newObj = {
              alwaysCall: unitPeopleState.alwaysCall,
              getNotifications: unitPeopleState.getNotifications,
              allowedToApprove: !!unitPeopleState.allowedtoApprove,
              emergencyContact: unitPeopleState.emergencyContact,
              canManagePeople: unitPeopleState.canManagePeople,
              allowedToChekoutKey: unitPeopleState.allowedToChekoutKey,
              allowedToClaimPackages: unitPeopleState.allowedToClaimPackages,
              allowedToAuthorizeEntry: unitPeopleState.allowedToAuthorizeEntry,
              alwaysNeedsEntrancePermission:
                unitPeopleState.alwaysNeedsEntrancePermission,
              allowedToRequestAccess: unitPeopleState.allowedToRequestAccess,
            };
            mutate({
              unitPeopleLinkId: unitPeopleState.id,
              payload: newObj,
            });
          }}
          isLoading={isLoading}
          isDisabled={isLoading || !submitButtonActive}
          isLoadingText="Submitting ..."
          size="lg"
          py="3"
          mb="4">
          {'Update '}
        </Button>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 4,
    paddingBottom: 12,
  },
});
