// @ts-nocheck
import {useNavigation} from '@react-navigation/native';
import {AxiosError} from 'axios';
import {useMutation} from 'react-query';
import {useDispatch} from 'react-redux';
import {residentApiWithAuth} from '../api/resident';
import {GetPeople} from '../modules/People/peopleReducer';
import {showErrorMessage, showSuccessMessage} from '../service/flashMessage';
import E_QUERY_NAMES from './query-names';

const updatePermissions = async ({unitPeopleLinkId, payload}) => {

  const response = await residentApiWithAuth().post(
    `/unitPeopleLinks/update/${unitPeopleLinkId}`,
    payload,
  );
  return response;
};

export function useUpdatePermissions() {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  return useMutation(E_QUERY_NAMES.UPDATE_PERMISSIONS, updatePermissions, {
    onSuccess: data => {
      dispatch(GetPeople());
      showSuccessMessage('Permissions updated');
      navigation.goBack();
    },
    onError: (error: AxiosError) => {
      const message = error.response.data.errors[0];
      showErrorMessage(message);
    },
  });
}
