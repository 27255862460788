import { Text, StyleSheet } from 'react-native';
import React from 'react';
import getConfig from '../../../utils/getConfig';

export default () => {
  const environment = getConfig('MY_WORKS_ENVIRONMENT');
  const apiVersion = getConfig('MY_WORKS_API_SUFFIX');

  return (
    <Text style={styles.text}>
      {`V1.0.18-02.25.2025 ${environment} ${apiVersion}`}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    fontSize: 12,
    color: '#718096',
  },
});